import { Link } from "react-router-dom";

export default function NavigationBar (props) {
    return (
        <div className="navigation-bar">
          {props.link ? <Link className="back" to={props.link}>
          &lsaquo;
          </Link> : <span className="spacer"></span>}
          <h1 className="page-title">{props.header}</h1>
          <span className="spacer"></span>
      </div>
    )
}