import {Link} from 'react-router-dom';
import '../styles/NeighborhoodButton.scss';

function NeighborhoodButton (props) {
    return (
               <div className="nyubasicpromo adaptiveimage image parbase section">
                   <div className="neighborhood component" >
                       <div className="promo-text-component">
                           <Link to={`/locations?neighborhood=${props.neighborhood.id}`} className="promo neighborhoodAnchor">
                               <h6 className="promo-title">{props.neighborhood.hood}</h6>
                               <div className="promo-content">
                                   <p></p>
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
    )
}
export default NeighborhoodButton;