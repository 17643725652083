import { useEffect, useState, useRef, useCallback } from "react";
import axios from 'axios';
import { useSearchParams } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';

import {flyto} from '../store/mapSlice';
import {addCurrentID} from '../store/currentIDSlice';
import {TreeLinks, removeTreeListeners } from "../treeitem.js";
import * as datehelpers from "../datehelpers.js";
import MealList from "../components/MealList.js";
import HourListItem from "../components/HourListItem";

import "../styles/Menu.scss";
import balancedU from "../styles/img/balanced_u.png";
import sodium from "../styles/img/sodium.png";
import vegan from "../styles/img/vegan.png";
import vegetarian from "../styles/img/vegetarian.png";
import NavigationBar from "../components/NavigationBar";

function Menu(props) {
    const dispatch = useDispatch();
    const stableDispatch = useCallback(dispatch, []);
    const [searchParams] = useSearchParams();
    const urlDate = searchParams.get('date');
    const urlID = searchParams.get('locationID'); 
    const urlCoordinates = useRef([searchParams.get('coordinate1'), searchParams.get('coordinate2')]); 
    const neighborhood = searchParams.get('neighborhood') || 0;
    const locationData = useSelector(state => state.locations.value);

    const [loading, setLoading] = useState(true);
    const [mealList, setMealList] = useState([]);
    const [hourHeader, setHourHeader] = useState([]);
    const [hourList, setHourList] = useState([]);
    const [location, setLocation] = useState('');
    const [address, setAddress] = useState('');
    const [date, setDate] = useState(urlDate);
    
    useEffect( () => {
        window.scrollTo(0,0);
    }, [] );
  
    const getMenuData = useCallback(async function (selectedDate, selectedLocation) { //ajax call to get menu data for an individual location
        try {
            //prod:
            const menuResponse = await axios.get('https://afgibjiqy1.execute-api.us-east-1.amazonaws.com/default/nyu-it-dgcom-dining-dev', { params: { selectedDate: selectedDate, selectedLocation: selectedLocation } });
            //dev:
            //const menuResponse = await axios.get('/menuSample.json');
            let menuData = menuResponse.data;
            if(menuData.status === "success"){
                setLoading(false);
                let menu = menuData.menu;
                if(menu !== undefined && menu.periods.length > 0) {
                    let mealPeriods = menu.periods;
                    const newMealList= mealPeriods.map((mealPeriod)=> {
                        return (<div key={mealPeriod.name.replace(/\s+/g, '')}>
                            <MealList mealName={mealPeriod.name} categories={mealPeriod.categories}/>
                        </div>);
                    });
                    setMealList(newMealList);
                }
            }
        } catch (error) {
            console.error(error);
            setLoading(false);
            setMealList(<p><strong>Could not retreive menu information at this time. Please try again later.</strong></p>)
        }

    }, []);

    useEffect( () => {
        function getWeekSchedule(locationSchedules, selectedDate, selectedLocation) {
            let weekSchedule = [];
            for(let value of locationSchedules) {
                if (value.id === selectedLocation) {
                    setLocation(value.name);
                    let street = value.address?.street;
                    setAddress(<a href={`https://www.google.com/maps/place/${street}+New+York,+NY`}> {street} </a>);
                    if (value.schedules) {
                        weekSchedule = datehelpers.getHours(value.schedules, datehelpers.getDateFromNYCDateString(`${selectedDate} 12:00`));
                    }
                    break;
                }
            }
            if (weekSchedule.filter((daySchedule) => datehelpers.isInvalidDate(daySchedule.day)).length) { 
                // Checks if any date is invalid (usually they are all invalid if the user input an invalid date in #mealDate)
                setHourList(<p className="removable"><strong>Please input a valid date.</strong></p>);
            } else if (weekSchedule.length) { // there should always be a DaySchedule for each day of week, unless the location is not found
                const weekStartDay = weekSchedule[0].day;
                const weekEndDay = weekSchedule[weekSchedule.length - 1].day;
                setHourHeader(
                        <span className="removable hour-header-date">{datehelpers.getNYCDateStringFromDate(weekStartDay)}–{datehelpers.getNYCDateStringFromDate(weekEndDay)}</span>
                );
                setHourList(
                    weekSchedule.map((daySchedule) => {
                        const dayOfWeek = datehelpers.getNYCDateComponentsFromDate(daySchedule.day).dayOfWeek;
                        return (<HourListItem key={datehelpers.numToDay(dayOfWeek)} day={datehelpers.numToDay(dayOfWeek)} schedule = {daySchedule.hours.join(", ") || "Closed"}/>);
                    })
                )
            } else {
                setHourList(<p className="removable"><strong>There is no hours data available for this location at this time.</strong></p>);
            }  
        }
        getWeekSchedule(locationData, urlDate, urlID);
        getMenuData(urlDate, urlID);
        //fly to location on the map
        stableDispatch(flyto({type:'updateMapArea', value: {zoom:17, coordinates: [urlCoordinates.current[0],urlCoordinates.current[1]]}}));
        //make th fis the active location
        stableDispatch(addCurrentID({type:'addCurrentID', value: urlID}));

        return () => {
            stableDispatch(addCurrentID({type:'addCurrentID', value: 0}));
        };
    }, [locationData, urlDate, urlID, urlCoordinates, stableDispatch, getMenuData]);
  
    useEffect( () => {
        let trees = document.querySelectorAll('[role="tree"]');
        let treeArray = []
        for (var i = 0; i < trees.length; i++) {
            treeArray.push(new TreeLinks(trees[i]));
            treeArray[treeArray.length-1].init();
        }
        return (() => {
            treeArray.forEach(tree => removeTreeListeners(tree))
        })
    }, [mealList] );

    const handleSubmit = (event) => {
        event.preventDefault();
        setMealList([]);
        setLoading(true);
        getMenuData(date, urlID);
    }
    const handleChange = (event) => {
        setDate(event.target.value);
    }
    return (
        <div id="menucontainer">
            <NavigationBar header={location} link={`/locations?neighborhood=${neighborhood}`}/>
            <div className="component">
                <p id="message"></p>
            </div>
            <div className="component">
            <h4>Address:</h4><p id="location">{address}</p>
            </div>
            <div className="component" id="hours">
                <h4 className="removable hour-header">Hours</h4>
                {loading && 
                        <p id="loading">Loading Hours...</p>
                }
                {hourHeader}
                <span className="removable" role="list">
                    {hourList}
                </span>
            </div>
            
            <div className = "component">   
                <p id="error-text"></p>
                <h4 id="tree1">Menu</h4>             
                <form id="dateForm" onSubmit={handleSubmit}>
                    <label  htmlFor="mealDate">Menu date: </label>
                    <input name="mealDate" onChange={handleChange} id="mealDate" placeholder="Date of meal" type="date" min= "2020-01-01" max="2026-01-01" value={date}/>
                    <div className="nyupromobutton parbase"> 
                        <div id="submitDate">
                            <input type="submit" value="Go" className="promo-button-text"/>
                        </div>
                    </div>
                </form>
            </div>
            <div className = "component">
                {loading && 
                <p id="loading">Loading Menu...</p>
                }
                {mealList}
            </div>

            <div className = "component legend">
                <h4 id='allergenKey'>Allergen Key:</h4>
                    <span role="list" className="legend">
                        <div className="allergen" role="listitem"><img src={balancedU} alt="Balanced U"/> Balanced U </div>
                        <div className="allergen" role="listitem"><img src={sodium} alt="Sodium"/> Sodium </div>
                        <div className="allergen" role="listitem"><img src={vegan} alt="Vegan"/> Vegan </div>
                        <div className="allergen" role="listitem"><img src={vegetarian} alt="Vegetarian"/> Vegetarian </div>
                    </span>
                </div>
        </div>
    );
}

export default Menu;
