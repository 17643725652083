import { useEffect, useState } from "react";
import MenuItem from "./MenuItem.js"


function MenuCategory ( {items, categoryName} ) {
    const [itemList, setItemList] = useState([]);

    useEffect(() => {
        let abridgedItems = [];
        items.forEach(item => {
            if (abridgedItems.indexOf(item.name) === -1) {
                let portion = '';
                if('portion' in item){
                    if(item.portion.length>0){
                        portion = '('+ item.portion + ')';
                    }
                }
                abridgedItems.push({'name': item.name, 'portion':portion, 'calories': item.calories, 'filters': item.filters});
            }
            setItemList(abridgedItems.map((abridgedItem) => {
                return (
                    <MenuItem key={abridgedItem.name} item={abridgedItem}/>
                )
            }))
        });
    }, [ items ])


    return (
        <li key={categoryName} role="treeitem" aria-expanded="false" tabIndex="-1"> 
            <span className="category">{categoryName}</span>
            <ul role="group" className="itemList">
                {itemList}
            </ul>
        </li>
    )

}

export default MenuCategory;