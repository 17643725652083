import { createSlice } from '@reduxjs/toolkit'

export const mapSlice = createSlice({
  name: 'map',
  initialState: {
        coordinates: [-73.9857,40.71349], 
        zoom: [12],
        features: [],
        markers: []
  },
  reducers: {
    flyto: 
      (state = {coordinates: [-73.9857,40.71349], 
                zoom: [12], 
                features: [], 
                markers: []}, 
        action = {}) => { 
          switch(action.type) {
            case 'updateFeatures':
              const { newFeatures } = action.payload.value;
              return {...state.value, features: newFeatures};
            case 'updateMarkers':
              const { newMarkers} = action.payload.value;
              return {...state.value, markers: newMarkers};
            case 'updateMapArea':
              const { newZoom, newCoordinates} = action.payload.value;
              return {...state.value, zoom: newZoom, coordinates: newCoordinates};
            default :
              return action.payload.value;
          }
        }
  }
})

export const { flyto } = mapSlice.actions;
export const selectMapState = state => state.map.value;

export default mapSlice.reducer;