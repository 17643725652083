import {
  HashRouter,
  Route
} from "react-router-dom";
import { useEffect, createContext, useState } from 'react';
import { useDispatch } from 'react-redux';
import SlideRoutes from 'react-slide-routes';
import axios from 'axios';

import { addLocations } from './store/locationSlice';
import Menu from './views/Menu';
import Locations from './views/Locations';
import Neighborhoods from './views/Neighborhoods';
import Footer from './components/Footer';
import Map from './components/Map';
import './styles/App.scss';
import './styles/fonts.scss';
import 'font-awesome/css/font-awesome.min.css';

function App() {
  const dispatch = useDispatch();

  useEffect( () => {
    try {
      //prod:
      axios.get('locations.json').then(response =>  dispatch(addLocations(response.data)));
      //axios.get('/locations.json').then(response =>  dispatch(addLocations(response.data)));
    } catch (err) {
      // If something went wrong, handle it here
      console.error(err);
    }
  }, [dispatch] );

  return (
    <div className="dining">
          <HashRouter basename="/">
              <SlideRoutes >
                  <Route exact path="/menu" element={<Menu/>}/>
                  <Route exact path="/locations" element={<Locations/>}/>
                  <Route exact path="/" element={<Neighborhoods/>}/>
                  <Route exact path="/index.html" element={<Neighborhoods/>}/>
              </SlideRoutes>
          </HashRouter>
      
        <Map />
        <Footer />
    </div>
      
  );
}

export default App;