import { useEffect, useState } from "react";

function MenuItem ( { item } ) {
    const [filterList, setFilterList] = useState([]);

    useEffect(() => {
        if(item.filters !==undefined){
            let dietaryRestrictions = item.filters;
                let restrictionNames = ['balanced u','vegetarian','vegan','sodium'];
    
                //get filters, make sure it's in our icon list, and add to results
                setFilterList(dietaryRestrictions.filter((restriction) => restrictionNames.indexOf(restriction.name.toLowerCase().replace('*', ''))>-1 )
                .map((restr) => {
                        return(
                            <img key={restr.id} src={`/img/${restr.name.toLowerCase().replace(/\*| /g, '')}.png`} alt={restr.name} title={restr.name}/>
                        ); 
                }));
        }
    }, [ item.filters ])

        return (
            <li role="treeitem" tabIndex="-1">
                <span className="menu-item"> 
                    <span className="name">{item.name}</span>
                    {item.filters && filterList}
                    <span className="calories">{item.calories}cal</span><br/>
                </span>
            </li>
        )
}

export default MenuItem;
