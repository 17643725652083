import { configureStore } from '@reduxjs/toolkit';
import mapReducer from './mapSlice';
import locationReducer from './locationSlice';
import currentIDReducer from './currentIDSlice';

export default configureStore({
  reducer: {
    map: mapReducer,
    locations: locationReducer,
    currentID: currentIDReducer
  }
})