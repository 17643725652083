import moment from 'moment';
import 'moment-timezone';
export const NYCTimezoneId = 'America/New_York';

export function numToDay(dayNumber){
    let day;
    switch(dayNumber) {
        case 0:
            day = "Sunday";
            break;
        case 1:
            day = "Monday";
            break;
        case 2:
             day = "Tuesday";
            break;
        case 3:
            day = "Wednesday";
            break;
        case 4:
            day = "Thursday";
            break;
        case 5:
            day = "Friday";
            break;
        case 6:
            day = "Saturday";
            break;
        default: 
            console.log("Invalid weekday value for hours");
    }
    return day;
}
export function getCurrentDate(separator=''){

    let newDate = new Date()
    let date = ('0'+ newDate.getDate()).slice(-2);
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();
    
    return `${year}${separator}${month<10?`0${month}`:`${month}`}${separator}${date}`
}
/**
* Components of a date
*
* @typedef {{dayOfWeek: number, month: number, hour: number, year: number, millisecond: number, day: number, minute: number, second: number}} DateComponents
*/
   /** Converts date components in NYC time zone to correct local Date object, with an optional offset
*
* @param dateComponents
* @param offset
* @return {Date}
*/
export function getDateFromNYCDateComponents(dateComponents, offset = {}) {
    return moment.tz(dateComponents, NYCTimezoneId).add(offset).toDate();
}
/**
 * Converts date object to NYC time zone
 *
 * @param date {Date}
 * @return {DateComponents}
 */
export function getNYCDateComponentsFromDate(date) {
    if (!date.valueOf()) { // Checks for invalid dates
        return ({
            year: NaN,
            month: NaN, // 0 for Jan, 11 for Dec
            day: NaN,
            hour: NaN,
            minute: NaN,
            second: NaN,
            millisecond: NaN,
            dayOfWeek: NaN
        });
    }
    const dateString = moment(date).tz(NYCTimezoneId).format("YYYY-M-D-H-m-s-SSS-d");
    const dateComponents = dateString.split("-").map(Number);
    return ({
        year: dateComponents[0],
        month: dateComponents[1] - 1, // 0 for Jan, 11 for Dec
        day: dateComponents[2],
        hour: dateComponents[3],
        minute: dateComponents[4],
        second: dateComponents[5],
        millisecond: dateComponents[6],
        dayOfWeek: dateComponents[7]
    });
}
/**
 * Converts the date object to NYC time zone and normalizes it to midday, with an optional offset
 *
 * @param date {Date}
 * @param offset
 * @return {Date}
 */
export function normalizeDateToNYCMidday(date, offset = {}) {
    const dateComponents = getNYCDateComponentsFromDate(date);
    return getDateFromNYCDateComponents([dateComponents.year, dateComponents.month, dateComponents.day, 12, 0], offset);
}
/** Converts the date object to NYC time zone and formats the date
 *
 * @param date {Date}
 * @param format {string}
 * @return {string}
 */
export function getNYCDateStringFromDate(date, format = "MMM D, YYYY") {
    return moment(date).tz(NYCTimezoneId).format(format);
}
/** Parses the date string in NYC time zone to a date object
 *
 * @param NYCDateString {string}
 * @param format {string}
 * @return {Date}
 */
export function getDateFromNYCDateString(NYCDateString, format = "YYYY-MM-DD H:mm") {
    return moment.tz(NYCDateString, format, NYCTimezoneId).toDate();
}
/**
 * Returns the open hours for a specific day, sorted by start time
 *
 * @param schedules
 * @param normalizedDay {Date}
 * @return {[Date, Date][]}
 */
export function getHoursForDay(schedules, normalizedDay) {
    const normalizedStamp = normalizedDay.getTime();
    const timeInNYC = getNYCDateComponentsFromDate(normalizedDay);
    let specialSchedules = [];
    let regularSchedules = [];
    let hasSpecialHours = false;
    /**
     * Extracts the open hours of a specific schedule
     *
     * @param schedule
     * @return {[Date, Date]}
     */
    function getHoursFromSchedule(schedule) {
        const open = getDateFromNYCDateComponents([timeInNYC.year, timeInNYC.month, timeInNYC.day, schedule.start_hour, schedule.start_minutes]);
        const close = getDateFromNYCDateComponents([timeInNYC.year, timeInNYC.month, timeInNYC.day, schedule.end_hour, schedule.end_minutes]);
        return [open, close];
    }
    for (let schedule of schedules) {
        if (Array.isArray(schedule.days)){
            const normalizedStart = normalizeDateToNYCMidday(new Date(schedule.start));
            const normalizedEnd = normalizeDateToNYCMidday(new Date(schedule.end));
            if (normalizedStamp >= normalizedStart.getTime() && normalizedStamp <= normalizedEnd.getTime()){
                if (schedule.type === "closed") {
                    if (schedule.days.length === 0 || schedule.days.indexOf(timeInNYC.dayOfWeek) > -1) {
                        // if the schedule has `type` "closed" and has either an empty `days` array or a `days` array tha includes the today's day of week, the location is considered closed.
                        return [];
                    }
                } else if (schedule.type === "special_hours") {
                    hasSpecialHours = true;
                    if (schedule.days.indexOf(timeInNYC.dayOfWeek) > -1) {
                        // if the schedule has `type` "special_hours" and has a `days` array tha includes the today's day of week, cache it
                        specialSchedules.push(getHoursFromSchedule(schedule));
                    }
                } else {
                    if (!hasSpecialHours && schedule.days.indexOf(timeInNYC.dayOfWeek) > -1) {
                        // if the schedule has `type` other than "closed" or "special_hours" and has a `days` array tha includes the today's day of week, cache it
                        regularSchedules.push(getHoursFromSchedule(schedule));
                    }
                }
            }
        }
    }
    if (hasSpecialHours) {
        return specialSchedules.sort((a, b) => (a[0].getTime() - b[0].getTime()) || (a[1].getTime() - b[1].getTime()));
    } else {
        return regularSchedules.sort((a, b) => (a[0].getTime() - b[0].getTime()) || (a[1].getTime() - b[1].getTime()));
    }
}
//check if a location is currently open
export function checkHours(schedules) {
    let now = new Date();
    let tStamp = now.getTime();
    const normalizedNow = normalizeDateToNYCMidday(now);
    for (let hours of getHoursForDay(schedules, normalizedNow)) {
        if (tStamp >= hours[0].getTime() && tStamp <= hours[1].getTime()) {
            return true;
        }
    }
    return false;
}
/**
 * Get hours by location and day and add them to a weekSchedule array to output more easily
 *
 * @param id
 * @param weekOf {Date}
 * @return {DaySchedule[]}
 */
export function getHours(schedules, weekOf = new Date()) {
    const currentDayOfWeek = getNYCDateComponentsFromDate(weekOf).dayOfWeek;
    /**
     * Formats date object tuple into string
     *
     * @param hours {[Date, Date]}
     * @return {string}
     */
    function formatHours(hours) {
        const open = getNYCDateComponentsFromDate(hours[0]);
        const close = getNYCDateComponentsFromDate(hours[1]);
        return moment(open.hour + ":" + open.minute, 'H:m').format('h:mmA') + '–' + moment(close.hour + ":" + close.minute, 'H:m').format('h:mmA');
    }
    let weekSchedule = [];
    
    for (let dayOfWeek = 0; dayOfWeek <= 6; ++dayOfWeek) {
        const normalizedDay = normalizeDateToNYCMidday(weekOf, { day: dayOfWeek - currentDayOfWeek }); // normalizes today and move to the corresponding day of week
        const formattedHours = getHoursForDay(schedules, normalizedDay).map(formatHours);
            weekSchedule.push(new DaySchedule(normalizedDay, formattedHours))
    }
    return weekSchedule;
}
/**
 * @param day {Date}
 * @param hours {string[]}
 * @constructor
 */
export function DaySchedule(day, hours) {
    this.day = day;
    this.hours = hours;
}
/**
 * Checks if a date is valid
 *
 * @param date {Date}
 * @return {boolean}
 */
export function isInvalidDate(date) {
    return (date instanceof Date) && isNaN(date.valueOf());
}
/**
 * Checks if #mealDate has a valid date value and sets it to the date of today in NYC if originally invalid.
 * Returns the date value in #mealDate after the evaluation.
 *
 * @return {string}
 */
export function checkSelectedDate() {
    const mealDateEl = document.getElementById('mealDate');
    if (isInvalidDate(moment(mealDateEl.value, "YYYY-MM-DD").toDate())) {
        mealDateEl.value = getNYCDateStringFromDate(new Date(), "YYYY-MM-DD");
    }
    return mealDateEl.value;
}

