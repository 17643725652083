import { useCallback, useEffect, useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import {flyto} from '../store/mapSlice';
import {addCurrentID} from '../store/currentIDSlice';
import LocationRow from '../components/LocationRow';
import NavigationBar from '../components/NavigationBar';
import { useSearchParams } from "react-router-dom";
import '../styles/Locations.scss';
import { neighborhoods } from '../constants.js';

function Locations() {
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();
  const stableDispatch = useCallback(dispatch, []);
  const locationData = useSelector(state => state.locations.value);

  const neighborhood = neighborhoods[searchParams.get('neighborhood')] || neighborhoods[0]; 

  const [locationList, setLocationList] = useState([]);

  useEffect( () => {
    const urlCoordinates = neighborhood.coordinates;
      //calculating distance to help define neighborhoods
    function straightLineDist(lat1,lon1,lat2,lon2) {
        var R = 6371; // Radius of the earth in km
        var dLat = deg2rad(lat2-lat1);  // deg2rad below
        var dLon = deg2rad(lon2-lon1);
        var a =
          Math.sin(dLat/2) * Math.sin(dLat/2) +
          Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) *
          Math.sin(dLon/2) * Math.sin(dLon/2)
          ;
        var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
        var d = R * c; // Distance in km
        return d;
    }

    function deg2rad(deg) {
          return deg * (Math.PI/180)
    }
    function getLocations(selectedCoordinates) {
      let locationRow=[];
      let rows=[];
      let locationsInNeighborhood=[];
  
      locationData.forEach((value) => {
          //determine which locations are in the neighborhood by comparing each location id to exceptions or measuring the distance of each location from selectedCoordinates
          if(straightLineDist(value.address.coordinates[0], value.address.coordinates[1], parseFloat(selectedCoordinates[0]), parseFloat(selectedCoordinates[1]))<0.4){
                  locationsInNeighborhood.push(value);
          }
      });
  
      locationsInNeighborhood.forEach((value, index) => {
        if(index / 3 >=1 && index % 3 === 0) {
          rows.push(locationRow);
          locationRow=[];
        }
        locationRow.push(value);
      });
      if(locationRow.length>0){
        rows.push(locationRow);
      }
  
      setLocationList(rows.map((row)=> {
        return (<div key={row[0].id} >
            <LocationRow neighborhood={neighborhood.id} row={row}/>
        </div>);
        })
      )
    }

    getLocations(urlCoordinates); 
  }, [locationData]);

  useEffect( () => {
    const urlCoordinates = neighborhood.coordinates || [-73.9857,40.71349];
    stableDispatch(flyto({type:'updateMapArea', value: {zoom:15, coordinates: [urlCoordinates[0],urlCoordinates[1]]}}));
    stableDispatch(addCurrentID({type:'addCurrentID', value: 0}));
    window.scrollTo(0,0);
  }, [stableDispatch, neighborhood]);

  return (
    <div id="locationContainer">
       <NavigationBar header="Locations" link="/"/>
       <div className="nyupromobutton parbase">
           <div className="component">
           
           </div>
       </div>
       {locationList}
   </div>
  );
}

export default Locations;
