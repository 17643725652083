import { useRef, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {selectMapState, flyto} from '../store/mapSlice';

import fork from '../styles/img/restaurant-fork-shorter-01.svg';
import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import 'mapbox-gl/dist/mapbox-gl.css';
import '../styles/Map.scss';
import {getCurrentDate} from '../datehelpers.js';

mapboxgl.accessToken = 'pk.eyJ1IjoibnYyNCIsImEiOiJjazVzbmlubHEwcThjM2tvNjk4enZ4dTJ4In0.acR7phhZxxseHwEWQJpREg';
function Map (props) {
    const dispatch = useDispatch();
    const stableDispatch = useCallback(dispatch, []);
    const mapContainer = useRef(null);
    const map = useRef(null);
    //const { zoom, coordinates, features, markers } = useSelector( state => state.map.value );
    const zoom = useSelector( state => state.map.zoom );
    const coordinates = useSelector( state => state.map.coordinates );
    const features = useSelector( state => state.map.features );
    const markers = useSelector( state => state.map.markers );
    const locationData = useSelector(state => state.locations.value);
    const currentID = useSelector(state => state.currentID.value);
    const date = getCurrentDate('-');

    
    
    function renderFeatures(mapFeatures) {
        //adjust map bounding box
        const geoBounds = map.current.getBounds();
        if(!mapFeatures || !markers) { return false;}
        markers.forEach((marker) => {
            marker.remove();
        })
        stableDispatch(flyto({type:'updateMarkers', value: { markers: [] }}));
        
        mapFeatures.forEach(function(marker) {
            // make a marker for each feature and add to the map
            if(geoBounds.contains(marker.geometry.coordinates)){
                // create an HTML element for each feature if it is in the current geographic bounds
                let el = document.createElement('div');
                let popupDiv = document.createElement('div');
                popupDiv.setAttribute('role', 'modal');
                popupDiv.innerHTML = 
                    `<p class="mapLocation">
                        <strong>
                            <a class="popupHeading" tabindex="0" 
                                href="#/menu?date=${date}&locationID=${marker.properties.ID}&coordinate1=${marker.geometry.coordinates[1]}&coordinate2=${marker.geometry.coordinates[0]}" 
                                data-id="${marker.properties.ID}">${marker.properties.Name}
                            </a>
                        </strong>
                        <br />${marker.properties.Description}
                    </p>`;

                const popup = new mapboxgl.Popup({ offset: 25, closeOnClick: true, closeOnMove: true, focusAfterOpen: true }).setDOMContent(popupDiv);
                el.className = 'marker';
                el.setAttribute('aria-haspopup', 'dialog');
                el.setAttribute('role', 'button');
                el.setAttribute('aria-label', marker.properties.Name);
                el.setAttribute('title', marker.properties.Name);
                el.setAttribute('tabindex', 0);
                el.style.backgroundImage =`url(${fork})`;
                el.style.backgroundPosition = 'top';
                el.style.backgroundSize = 'cover';
                if(marker.properties.ID === currentID.value) {
                    el.style.width = '35px';
                    el.style.height = '55px';
                    el.style.zIndex = '10';
                }
                else {
                    el.style.width = '21px';
                    el.style.height = '32px';
                    el.style.zIndex = '1';
                }
                el.className = 'mapboxgl-accessibility-marker';
                let newMarker = new mapboxgl.Marker(el)
                    .setLngLat(marker.geometry.coordinates)
                    .setPopup(popup) // add popups

                    //.setOffset(mapOffset)
                    .addTo(map.current);
                stableDispatch(flyto({type:'updateMarkers', value: { markers: [...markers, newMarker] }}));
            }
        });
    }

    useEffect(() => {
        if (map.current) return; // initialize map only once
        map.current = new mapboxgl.Map({
        container: mapContainer.current,
        style: 'mapbox://styles/nv24/ck6ayd4yz010s1iqtkl4f1t0l',
        center: [-73.9857,40.71349],
        zoom: zoom,
        scrollZoom      : false,
        boxZoom         : false,
        doubleClickZoom : false,
        dragging        : false
        });
    }, [zoom]);

    // useEffect(() => {
    //     console.log(coordinates);
    //     if (!map.current) return; // wait for map to initialize
    //     map.current.on('move', () => {
    //         dispatch(flyto({type:'flyto', value: {zoom:map.current.getZoom().toFixed(2), coordinates: [map.current.getCenter().lng,map.current.getCenter().lat]}}));
    //     });
    // });

    useEffect(() => {
        const destination = coordinates ? coordinates : [-73.9857,40.71349];
        let newBearing = -10;
        if(destination[0] === -73.98646868) {
            newBearing=75;
        }
        else if (destination[0] === -73.9893209) {
            newBearing=135;
        }
        map.current.flyTo({ center: [ parseFloat(destination[0]), parseFloat(destination[1])], zoom: zoom || 12, bearing: newBearing, container: 'map', antialias: true});
        renderFeatures(features);
    }, [coordinates, zoom]);

        
    useEffect(() => {
        const mapFeatures = locationData.map(element => {
            let feature = {};
            feature.Type="Feature";
    
            // TODO: Switch after backend change
            if (element.status) {
                feature.properties = {ID: element.id,Name: element.name, Address: element.address?.street,Description: element.status.message};
            } else {
                feature.properties = {ID: element.id,Name: element.name, Address: element.address?.street,Description: element.description};
            }
    
            if (Array.isArray(element.address?.coordinates)) {
                feature.geometry = {coordinates: [element.address.coordinates[0]+(Math.random() * 0.00004 - 0.00002), element.address.coordinates[1]+(Math.random() * 0.00004 - 0.00002)], type:'Point'};
            } else {
                feature.geometry = {coordinates: [element.address?.lon, element.address?.lat], type:'Point'};
            }
            return feature;
        });
        stableDispatch(flyto({type:'updateFeatures', value: { features: mapFeatures }}));
        renderFeatures(mapFeatures);
        //setMapFeatures(features);
    }, [locationData])
        

    // useEffect(() => {
    //     map.current.on("wheel", event => {
    //         if (event.originalEvent.ctrlKey) {
    //           return;
    //         }
          
    //         if (event.originalEvent.metaKey) {
    //           return;
    //         }
          
    //         if (event.originalEvent.altKey) {
    //           return;
    //         }
          
    //         event.preventDefault();
    //     });
    //     map.current.on('zoom', () => {
    //         renderFeatures(mapFeatures);
    //     });
    // })

    return (
        <div className="component" id="map">
            <div ref={mapContainer} className="map-container" />
        </div>
    );
}

export default Map;