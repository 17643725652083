import { createSlice } from '@reduxjs/toolkit';

//keeps track of when we are focused in on a particular location
//so that the map icon of that location can be made more prominent
export const currentIDSlice = createSlice({
  name: 'currentID',
  initialState: {
      value: 0
  },
  reducers: {
    addCurrentID: (state,action) => {
        state.value=action.payload;
      }
  }
})

export const { addCurrentID } = currentIDSlice.actions;
export default currentIDSlice.reducer;