import { useEffect, useCallback } from 'react';
import NeighborhoodList from '../components/NeighborhoodList.js';
import NavigationBar from '../components/NavigationBar';
import { useDispatch } from 'react-redux';

import {flyto} from '../store/mapSlice';
import '../styles/Neighborhoods.scss';
import { neighborhoods } from '../constants.js';

function Neighborhoods () {
  const dispatch = useDispatch();
  const stableDispatch = useCallback(dispatch, []);    
  
  useEffect( () => {
    stableDispatch(flyto({type:'updateMapArea', value: {zoom:12, coordinates: [-73.9857,40.71349]}}));
    window.scrollTo(0,0);
  }, []);
    
  return (
      <div id="neighborhoods">
        <NavigationBar header="Neighborhoods"></NavigationBar>
            {/* <h2>Where would you like to eat?</h2> */}
            <NeighborhoodList neighborhoods={neighborhoods}/>
  </div>
  )
    
}

export default Neighborhoods;